<template>
  <section>
      <div class="content-header">
        <h2>Works cited</h2>
      </div>
      <div class="content-wrapper">
        <p>Here are the resources cited throughout this section of the toolkit, in the order in which they appeared.  </p>
       <ul>
         <li class="pb-3">Ryerson University. (2020). <em>Research Guides: Mental Health and ThriveRU Resources: ThriveRU</em>. Ryerson University Library. This resource was curated by Diana Brecher for the Ryerson Library between 2016-2022<br><a href="https://learn.library.ryerson.ca/MentalHealth/ThriveRU" target="_blank">learn.library.ryerson.ca/MentalHealth/ThriveRU</a> </li>
         <li class="pb-3"><em>Boosting Student Resilience with Dr. Diana Brecher</em>. (2017, June 22). Scott Barry Kaufman. <br><a href="https://scottbarrykaufman.com/podcast/boosting-student-resilience-w-dr-diana-brecher/" target="_blank">scottbarrykaufman.com/podcast/boosting-student-resilience-w-dr-diana-brecher/</a></li>
         <li class="pb-3">Brecher, D. (2020). Flow video.</li>
         <li class="pb-3"><em>Jumpstarting Creativity</em>. (n.d.). NPR. <a href="https://choice.npr.org/index.html?origin=https://www.npr.org/programs/ted-radio-hour/719553183/jumpstarting-creativity" target="_blank">choice.npr.org/index.html?origin=https://www.npr.org/programs/ted-radio-hour/719553183/jumpstarting-creativity</a></li>
         <li class="pb-3">Allen, D. (2020, April 16). <em>How to find “flow” (and lose yourself in it</em>). TED Talks. <a href="https://www.ted.com/talks/diane_allen_how_to_find_flow_and_lose_yourself_in_it" target="_blank">www.ted.com/talks/diane_allen_how_to_find_flow_and_lose_yourself_in_it</a></li>
         <li class="pb-3"><em>How To Get Into The Flow State | Steven Kotler</em>. (2019, February 19). [Video]. YouTube. <br><a href="https://www.youtube.com/watch?v=XG_hNZ5T4nY" target="_blank">www.youtube.com/watch?v=XG_hNZ5T4nY</a></li>
         <li class="pb-3"><em>Mihaly Csikszentmihalyi: Flow, the secret to happiness</em>. (2008, October 24). YouTube.<br> <a href="https://www.youtube.com/watch?v=fXIeFJCqsPs" target="_blank">www.youtube.com/watch?v=fXIeFJCqsPs</a></li>
         <li class="pb-3"><em>Articles From Steven Kotler</em>. (n.d.). Steven Kotler. <br><a href="https://www.stevenkotler.com/articles" target="_blank">www.stevenkotler.com/articles</a></li>
         <li class="pb-3">Csikszentmihalyi, M. (2008). <em>Flow: The Psychology of Optimal Experience (Harper Perennial Modern Classics)</em> (1st ed.). Harper Perennial Modern Classics. <br><a href="https://www.harpercollins.ca/9780061339202/flow" target="_blank">www.harpercollins.ca/9780061339202/flow</a></li>
         <li class="pb-3">Csikszentmihalyi, M. (2009). <em>Creativity: Flow and the Psychology of Discovery and Invention</em>. HarperCollins e-books. <br><a href="https://www.harpercollins.com/products/creativity-mihaly-csikszentmihalyi?variant=32205941211170" target="_blank">www.harpercollins.com/products/creativity-mihaly-csikszentmihalyi?variant=32205941211170</a></li>
         <li class="pb-3">Kotler, S. (2014). <em>The Rise of Superman: Decoding the Science of Ultimate Human Performance</em>. New Harvest. <br><a href="https://www.stevenkotler.com/book-pages/the-rise-of-super-man" target="_blank">www.stevenkotler.com/book-pages/the-rise-of-super-man</a></li>
         <li class="pb-3">Sumaya, I.C., & Darling, E. (2018). Procrastination, flow, and academic performance in real time using the experience sampling method. <br><a href="https://doi.org/10.1080/00221325.2018.1449097" target="_blank">https://doi.org/10.1080/00221325.2018.1449097</a></li>
         <li class="pb-3">Joo, Y. J., Joung, S., & Kim, J. (2014). Structural relationships among self-regulated learning, learning flow, satisfaction, and learning persistence in cyber universities. <em>Interactive Learning Environments</em>, 22(6), 752-770. <a href="https://doi.org/10.1080/10494820.2012.745421" target="_blank">doi.org/10.1080/10494820.2012.745421</a></li>
         <li class="pb-3">Adil, A., Ameer, S., & Ghayas, S. (2020). Impact of academic psychological capital on academic achievement among university undergraduates: Roles of flow and self‐handicapping behavior. <em>PsyCh Journal</em>, 9(1), 56-66. <a href="https://doi.org/10.1002/pchj.318" target="_blank">doi.org/10.1002/pchj.318</a></li>
         <li class="pb-3">Whitney, H, Goddard, K., & Favero, C. (2021) Co-curricular program that encourages specific study skills and habits improves academic performance and retention of first-year undergraduates in introductory biology. CBE—Life Sciences Education, 20(1), ar4<br><a href="https://www.lifescied.org/doi/10.1187/cbe.20-06-0117" target="_blank">www.lifescied.org/doi/10.1187/cbe.20-06-0117</a></li>
         <li class="pb-3">Horowitz, G. (2017). First-generation college students: How to recognize them and be their ally and advocate. Journal of College Science Teaching 46(6), 8-9. DOI: 10.2505/4/jcst17_046_06_8. <br><a href="https://www.proquest.com/openview/0b0995ce04f0772baefd323e25388e4a/1?pq-origsite=gscholar&cbl=49226" target="_blank">www.proquest.com/openview/0b0995ce04f0772baefd323e25388e4a/1?pq-origsite=gscholar&cbl=49226</a></li>
         <li class="pb-3">Goodlowe-Scott, Q. N. (2017). Examining the relationships between student learning habits and post-secondary educational outcomes: The missing component. Dissertation - Morgan University <a href="https://www.proquest.com/openview/36c3fce6808f43f8e557beb9dcf6fb7c/1?pq-origsite=gscholar&cbl=18750" target="_blank">www.proquest.com/openview/36c3fce6808f43f8e557beb9dcf6fb7c/1?pq-origsite=gscholar&cbl=18750</a></li>
         <li class="pb-3">Gomes, A.A., Tavares, J., & de Azevedo, M.H.P. (2011). Sleep and academic performance in undergraduates: a multi-measure, multi-predictor approach. <em>Chronobiology international, 28</em>(9), 786-801 <a href="https://doi.org/10.3109/07420528.2011.606518" target="_blank">doi.org/10.3109/07420528.2011.606518</a></li>
         <li class="pb-3">Carney, C., (2020) The DOZE App: A Unique Approach to Overcoming Sleep Problems in Young Adults. <em>Scientia</em>.  <a href="https://www.scientia.global/dr-colleen-carney-the-doze-app-a-unique-approach-to-overcoming-sleep-problems-in-young-adults/" target="_blank">www.scientia.global/dr-colleen-carney-the-doze-app-a-unique-approach-to-overcoming-sleep-problems-in-young-adults/</a></li>
         <li class="pb-3">Campbell, R., Soenens, B., Beyers, W., & Vansteenkiste, M. (2018). University students’ sleep during an exam period: The role of basic psychological needs and stress. <em>Motivation and Emotion</em>, 42(5), 671-681. <br><a href="https://doi.org/10.1007/s11031-018-9699-x" target="_blank">doi.org/10.1007/s11031-018-9699-x</a></li>
         <li class="pb-3">Du, C., Zan, M. C. H., Cho, M. J., Fenton, J. I., Hsiao, P. Y., Hsiao, R., Keaver, L., Lai, C., Lee, H., Ludy, M., Shen, W., Swee, W. C. S., Thrivikraman, J., Tseng, K., Tseng, W., Doak, S., Folk, S. Y. L., & Tucker, R. M. (2021). The effects of sleep quality and resilience on perceived stress, dietary behaviors, and alcohol misuse: A mediation-moderation analysis of higher education students from Asia, Europe, and North America during the COVID-19 pandemic. <em>Nutrients</em>, 13(2), 442. <a href="https://doi.org/10.3390/nu13020442" target="_blank">doi.org/10.3390/nu13020442</a></li>
         <li class="pb-3">Ram-Vlasov, N., Tzischinsky, O., Green, A., & Shochat, T. (2016). Creativity and habitual sleep patterns among art and social sciences undergraduate students. <em>Psychology of Aesthetics, Creativity, and the Arts, 10</em>(3), 270-277. <a href="https://doi.org/10.1037/aca0000062" target="_blank">doi.org/10.1037/aca0000062</a></li>
         <li class="pb-3">Benham, G. (2021;2020;). Stress and sleep in college students prior to and during the COVID‐19 pandemic. <em>Stress and Health, 37</em>(3), 504-515. <br><a href="https://doi.org/10.1002/smi.3016" target="_blank">doi.org/10.1002/smi.3016</a></li>
         <li class="pb-3">Luthans, B.C., Luthans, K,W., & Chaffin, T.D. (2021) Character matters: The mediational impact of self-regulation on PsyCap and academic performance. <em>Journal of Education for Business,</em> 1-15 DOI:  <br><a href="https://www.tandfonline.com/doi/abs/10.1080/08832323.2021.1874856?journalCode=vjeb20" target="_blank">www.tandfonline.com/doi/abs/10.1080/08832323.2021.1874856?journalCode=vjeb20</a></li>
         <li class="pb-3">Lavy, S. (2020). A review of character strengths interventions in twenty-first-century schools: their importance and how they can be fostered. <em>Applied Research Quality Life</em> 15, 573–596. <br> <a href="https://link.springer.com/article/10.1007/s11482-018-9700-6" target="_blank">link.springer.com/article/10.1007/s11482-018-9700-6</a></li>
         <li class="pb-3">Karris Bachik, M. A., Carey, G., & Craighead, W. E. (2021;2020;). VIA character strengths among U.S. college students and their associations with happiness, well-being, resiliency, academic success and psychopathology. <em>The Journal of Positive Psychology, 16</em>(4), 512-525. <br><a href="https://doi.org/10.1080/17439760.2020.1752785" target="_blank">doi.org/10.1080/17439760.2020.1752785</a></li>
         <li class="pb-3">Gustems-Carnicer, J., & Calderon, C. (2016). Virtues and character strengths related to approach coping strategies of college students. <em>Social Psychology of Education, 19</em>(1), 77-95. <br><a href="https://doi.org/10.1007/s11218-015-9305-y" target="_blank">doi.org/10.1007/s11218-015-9305-y</a></li>
       </ul>
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '02',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
